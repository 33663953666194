<template>
  <div class="video-box" :style="{ width, height }">
    <video class="video-player video-js" :id="videoPlayerId" webkit-playsinline />
    <div class="video-error" v-if="loadError">
      <div class="video-error-tip">{{ loadMsg }}</div>
      <div class="video-error-action">
        <el-button
          size="mini"
          icon="el-icon-refresh"
          type="primary"
          @click="refreshVideo"
          >重新加载</el-button
        >
      </div>
    </div>
    <div class="video-loading" v-if="loadLoading">
      <div class="video-loading-spring">
        <img src="@/assets/logo.gif" alt="" />
      </div>
    </div>
    <div class="divOut" v-if="slideVerifyShow">
      <slide-verify
        :l="42"
        :r="10"
        :w="350"
        :h="180"
        ref="slideBlock"
        @again="onAgain"
        @success="onSuccess"
        @fail="onFail"
        :slider-text="text"
        :accuracy="3"
        :imgs="images"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "videoPlayer",
  props: {
    focus: {
      type: Boolean,
      default: false,
    },
    ifBrush: {
      type: Boolean,
      default: false,
    },
    brushTime: {
      type: Number,
      default: 1 * 60,
    },
    moveInPlay: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    currentUrl: {
      type: [Number, String],
      default: 0,
    },
    ifDragSeek: {
      type: Boolean,
      default: false,
    },
    dragTime: {
      type: Number,
      default: 0,
    },
    ifSwitch: {
      type: Boolean,
      default: true,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    muted: {
      type: Boolean,
      default: false,
    },
    isToSee: {
      type: Boolean,
      default: false,
    },
    isToSeeTime: {
      type: Number,
      default: 10,
    },
    isSwitch: {
      type: Boolean,
      default: true,
    },
    ifTabFocus: {
      type: Boolean,
      default: true,
    },
    playerList: {
      required: true,
      type: [Array, String],
      default: () => {
        [];
      },
    },
  },
  data() {
    return {
      slideVerifyShow: false,
      toSeeEl: false,
      images: [
        "/static/images/img1.jpg",
        "/static/images/img3.jpg",
        "/static/images/img4.jpg",
        "/static/images/img5.jpg",
        "/static/images/img6.webp",
        "/static/images/img7.webp",
      ],
      loadError: false,
      loadLoading: true,
      loadMsg: "",
      videoPlayerId: "video-player" + Math.ceil(Math.random() * 1000),
      playerUrl: undefined,
      playerEl: null,
      currentPlayerIndex: 0,
      seekAllow: 0,
      slideTime: 0,
      text: "休息一下，向右滑动，恢复播放",
      slideInterval: null,
      tooltipComponent: null,
    };
  },
  created() {
    this.customComponent();
    this.playerVideoInit();
    if (this.ifBrush) {
      this.slideCountDown();
    }
    if (this.ifTabFocus) {
      document.addEventListener(
        "visibilitychange",
        this.tabVisibilitychange,
        false
      );
    }
  },
  watch: {
    playerList() {
      this.playerVideoInit();
    },
    currentUrl() {
      this.slideCountDown();
    },
  },
  methods: {
    playerVideoInit() {
      if (typeof this.playerList !== "string") {
        if (typeof this.currentUrl == "number") {
          this.currentPlayerIndex = this.currentUrl;
        } else {
          this.currentPlayerIndex = this.playerList.indexOf(
            (item) => item.url === this.currentUrl
          );
        }
        if (this.currentPlayerIndex === -1) {
          this.$message.error("视频地址错误，请检查地址");
          return false;
        }
        this.playerUrl = this.playerList[this.currentPlayerIndex].url;
      } else {
        this.playerUrl = this.playerList;
      }
      this.$nextTick(() => {
        this.playerInit();
      });
    },
    playerInit() {
      const that = this;
      if (that.playerEl) {
        that.playerEl = null;
      }
      that.playerEl = window.videojs(
        that.videoPlayerId,
        {
          width: that.width,
          height: that.height,
          controls: true,
          autoplay: that.autoplay,
          language: "zh-CN", // 设置语言
          muted: that.muted, // 是否静音
          controlBar: {
            // fullscreenToggle: !that.isToSee,·
            volumePanel: {
              inline: false,
            },
            children: [
              { name: "playToggle" }, // 播放/暂停按钮
              { name: "currentTimeDisplay" }, // 当前播放时间
              { name: "MyCustomSeekBar" }, // 进度条
              { name: "durationDisplay" }, // 当前播放时间
              { name: "volumePanel" }, // 音量控制
              { name: "fullscreenToggle" }, // 全屏
            ],
          },
          sources: [
            // 视频来源路径
            {
              src: that.playerUrl,
              //视频类型
              type: "video/mp4",
            },
          ],
        },
        function onPlayerReady() {
          this.player_.playsinline(true)
          that.tooltipComponent = this.addChild("MyCustomTip");
          if (that.focus) {
            this.on("mouseleave", function () {
              if (!this.player_.paused()) {
                that.tooltipComponent.showTooltip("鼠标移除视频将会暂停！");
                this.player_.pause();
              }
            });
          }
          if (that.moveInPlay) {
            this.on("mouseenter", function () {
              if (this.player_.paused()) {
                this.player_.play();
              }
            });
          }
          this.on("contextmenu", function (event) {
            event.preventDefault();
          });
          this.on("loadstart", function () {
            // console.log("开始请求数据 ");
            that.loadError = false;
            that.loadLoading = true;
            that.loadstart(this);
          });
          this.on("progress", function () {
            // console.log("正在请求数据 ");
          });
          this.on("loadedmetadata", function () {
            // console.log("获取资源长度完成 ");
            that.loadLoading = false;
          });
          this.on("canplaythrough", function () {
            // console.log("视频源数据加载完成");
          });
          this.on("waiting", function () {
            // console.log("等待数据");
          });
          this.on("play", function () {
            // console.log("视频开始播放");
            that.play(this);
          });
          this.on("playing", function () {
            // console.log("视频播放中");
            that.playing(this);
          });
          this.on("pause", function () {
            // console.log("视频暂停播放");
            if (
              that.isToSee &&
              Math.floor(this.currentTime()) >= that.isToSeeTime
            ) {
              that.playerEl.pause();
              that.tooltipComponent.showTooltip("试看结束！");
            }
            that.pause(this);
          });
          this.on("ended", function () {
            // console.log("视频播放结束");
            if (that.isSwitch) {
              that.playerChange();
            }
            that.ended(this);
          });
          this.on("error", function () {
            // console.log("加载错误");
            that.loadMsg = this.error_.message;
            that.loadError = true;
            that.loadLoading = false;
            this.errorDisplay.close();
          });
          this.on("seeking", function () {
            that.seeking(this);
            // console.log("视频跳转中");
          });
          this.on("seeked", function () {
            // console.log("视频跳转结束");
            if (
              that.isToSee &&
              Math.floor(this.currentTime()) > that.isToSeeTime
            ) {
              that.playerEl.pause();
              that.playerEl.currentTime(that.isToSeeTime);
            }
            that.seeked(this);
          });
          this.on("ratechange", function () {
            // console.log("播放速率改变");
          });
          this.on("timeupdate", function () {
            // console.log("播放时长改变");
            if (that.isToSee) {
              // 是否试看
              if (Math.floor(this.currentTime()) >= that.isToSeeTime) {
                that.playerEl.pause();
                that.toSeeEl = true;
              }
            }
            that.timeupdate(this);
          });
          this.on("volumechange", function () {
            // console.log("音量改变");
          });
          this.on("stalled", function () {
            // that.loadMsg = "加载失败！请检查视频地址或者网络";
            // that.loadError = true;
            // that.loadLoading = false;
            // console.log("网速异常");
          });
        }
      );
    },
    slideCountDown() {
      const that = this;
      if (that.slideInterval) {
        clearInterval(that.slideInterval);
        that.slideInterval = null;
      }
      that.slideTime = that.brushTime;
      that.slideInterval = setInterval(() => {
        if (that.playerEl && !that.playerEl.paused()) {
          if (that.slideTime <= 0) {
            if (that.playerEl.isFullscreen()) {
              that.exitFullscreen();
            }
            that.slideVerifyShow = true;
            that.playerEl.pause();
            that.slideTime = that.brushTime;
            clearInterval(that.slideInterval);
            that.slideInterval = null;
          } else {
            that.slideTime--;
          }
        }
      }, 1000);
    },
    //退出全屏
    exitFullscreen() {
      if (document.exitFullScreen) {
        document.exitFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (element.msExitFullscreen) {
        element.msExitFullscreen();
      }
      return true;
    },
    playerChange() {
      if (this.currentPlayerIndex === this.playerList.length - 1) {
        this.$emit("overEnd");
        return false;
      }
      this.currentPlayerIndex++;
      if (this.ifSwitch) {
        this.playerEl.src(this.playerList[this.currentPlayerIndex].url);
        this.refreshVideo();
        this.$emit("switch", {
          index: this.currentPlayerIndex,
          playerList: this.playerList,
        });
      }
    },
    playerSwitch(index) {
      this.currentPlayerIndex = index;
      this.playerEl.src(this.playerList[this.currentPlayerIndex].url);
      this.refreshVideo();
    },
    loadstart() {
      this.$emit("loadStart", arguments[0]);
    },
    pause() {
      this.$emit("pause", arguments[0]);
    },
    play() {
      this.$emit("play", arguments[0]);
    },
    playing() {
      this.$emit("playing", arguments[0]);
    },
    ended() {
      this.$emit("ended", arguments[0]);
    },
    timeupdate() {
      this.$emit("timeupdate", arguments[0]);
    },
    seeking() {
      this.$emit("seeking", arguments[0]);
    },
    seeked() {
      this.$emit("seeked", arguments[0]);
    },
    setSeek(currentTime) {
      if (this.playerEl) {
        this.playerEl.currentTime(currentTime);
      }
    },
    refreshVideo() {
      if (this.slideInterval) {
        clearInterval(this.slideInterval);
        this.slideInterval = null;
      }
      if (this.playerEl) {
        if (this.playerEl.isFullscreen()) {
          this.exitFullscreen();
        }
        this.playerEl.load();
      }
    },
    onSuccess(times) {
      this.$emit("onSuccess");
      this.slideVerifyShow = false;
      this.slideCountDown();
      this.playerEl.play();
      console.log("验证通过，耗时 " + times + "毫秒");
    },
    // 验证失败
    onFail() {
      this.$emit("onFail");
      console.log("验证不通过");
    },
    // 检测是否人为操作
    onAgain() {
      console.log("检测到非人为操作的哦！");
      this.$emit("onAgain");
      this.msg = "再试一次";
      this.$refs.slideBlock.reset();
    },
    // 重置刷新
    handleClick() {
      this.$refs.slideBlock.reset();
    },
    customComponent() {
      const that = this;
      // 自定义进度条
      class MyCustomSeekBar extends window.videojs.getComponent("SeekBar") {
        constructor(player, options) {
          super(player, options);

          // 绑定 handleMouseUp 事件处理器
          this.on("mouseup", this.handleMouseUp);
          this.on("mousemove", this.handleMouseMove);
          this.on("touchend", this.handleMouseUp);
          this.on("touchmove", this.handleMouseMove);

          // 保存 seek 操作相关的变量
          this.userSeek_ = false;
        }

        handleMouseDown(event) {
          // 阻止默认行为，并可能开始拖动操作
          if (event.type === "mousedown") {
            event.preventDefault(); // 这将只针对鼠标事件
          }
          this.userSeek_ = true;
          this.update();

          // 其他的 handleMouseDown 逻辑...

          // 绑定 mouseup 和 touchend 事件处理器，以便在释放时执行 seek
          document.addEventListener("mouseup", () => this.handleMouseUp());
          document.addEventListener("touchend", () => this.handleMouseUp());

          // 绑定 mousemove 和 touchmove 事件处理器，以便在拖动时更新播放位置
          document.addEventListener("mousemove", this.handleMouseMove);
          document.addEventListener("touchmove", this.handleMouseMove);
        }

        handleMouseUp() {
          // 移除事件监听器
          document.removeEventListener("mouseup", this.handleMouseUp);
          document.removeEventListener("touchend", this.handleMouseUp);
          document.removeEventListener("mousemove", this.handleMouseMove);
          document.removeEventListener("touchmove", this.handleMouseMove);

          if (this.userSeek_) {
            this.userSeek_ = false;
            const rect = this.el_.getBoundingClientRect();
            const x =
              (event.pageX || event.changedTouches[0].pageX) - rect.left;
            const percent = x / rect.width;
            const time = percent * this.player_.duration();
            if (!that.ifDragSeek) {
              let currentTime = this.player_.currentTime();
              if (that.seekAllow < currentTime) {
                that.seekAllow = currentTime;
              }
              if (that.isToSee) {
                if (that.isToSeeTime < time) {
                  that.tooltipComponent.showTooltip("试看结束");
                  this.player_.currentTime(that.isToSeeTime);
                  this.player_.paused();
                } else {
                  this.player_.currentTime(time);
                }
              } else {
                if (that.seekAllow < time) {
                  that.tooltipComponent.showTooltip(
                    "您还未学习到该位置，还不能跳转"
                  );
                  this.player_.currentTime(currentTime);
                } else {
                  this.player_.currentTime(time);
                }
              }
            } else {
              this.player_.currentTime(time);
            }
          }
        }

        handleMouseMove() {
          if (this.userSeek_) {
            this.update();
          }
        }
      }
      window.videojs.registerComponent("MyCustomSeekBar", MyCustomSeekBar);

      // 自定义提示
      class MyCustomTip extends window.videojs.getComponent("Component") {
        constructor(player, options) {
          super(player, options);
          // 初始化定时器
          this.hideTooltipTimeout = null;
          this.hideTooltip();
        }

        // 创建提示元素的DOM结构
        createEl() {
          const el = super.createEl("div", {
            className: "vjs-tooltip",
            textContent: "",
            style: {
              display: "none",
              opacity: 0,
            },
          });

          return el;
        }

        showTooltip(message) {
          this.el_.textContent = "温馨提示：" + message;
          this.el_.style.display = "block";
          this.el_.style.opacity = 1;

          if (this.hideTooltipTimeout) {
            clearTimeout(this.hideTooltipTimeout);
          }

          this.hideTooltipTimeout = setTimeout(() => {
            this.hideTooltip();
          }, 3000);
        }

        hideTooltip() {
          this.el_.style.display = "none";
          this.el_.textContent = "";
          this.el_.style.opacity = 0;
        }

        // 组件销毁时清除定时器
        dispose() {
          clearTimeout(this.hideTooltipTimeout);
          super.dispose();
        }
      }
      window.videojs.registerComponent("MyCustomTip", MyCustomTip);
    },
    tabVisibilitychange() {
      if (this.playerEl) {
        if (document.visibilityState === "hidden" && !this.playerEl.paused()) {
          this.playerEl.pause();
          this.tooltipComponent.showTooltip("您离开该页面将会停止播放");
        }
      }
    },
  },
  beforeDestroy() {
    if (this.playerEl) {
      this.playerEl.dispose();
    }
    if (this.ifTabFocus) {
      document.removeEventListener(
        "visibilitychange",
        this.tabVisibilitychange
      );
    }
    if (this.slideInterval) {
      clearInterval(this.slideInterval);
      this.slideInterval = null;
    }
  },
};
</script>
<style scoped lang="scss">
.video-box {
  position: relative;
  height: 100%;
  width: 100%;
  .video-player {
    position: relative;
    width: 100%;
    height: 100%;
    ::v-deep .vjs-duration {
      display: block;
    }
    ::v-deep .vjs-current-time {
      display: block;
    }
  }
  .video-error {
    position: absolute;
    color: #ffffff;
    background-color: #000000;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 10px;
    z-index: 10;
    box-sizing: border-box;
    .video-error-tip {
      text-align: center;
      margin-top: 10%;
    }
    .video-error-action {
      margin-top: 20px;
      text-align: center;
    }
  }
  .video-loading {
    position: absolute;
    color: #ffffff;
    background-color: #000000;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 10px;
    z-index: 10;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .video-request-loading {
    position: absolute;
    color: #ffffff;
    background-color: transparent;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 10px;
    z-index: 10;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .video-loading-spring {
    position: relative;
    width: 70px;
    height: 70px;
    margin-bottom: 80px;
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      width: 70px;
      height: 70px;
      border: 5px solid #ffffff;
      border-top-color: transparent;
      border-radius: 100%;
      animation: circle infinite 0.75s linear;
    }
    img {
      width: 60px;
      height: 60px;
      position: absolute;
      top: 10px;
      left: 10px;
    }
  }
}
.divOut {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.9);
  ::v-deep .slide-verify {
    background-color: #ffffff;
    .slide-verify-slider {
      width: calc(100% - 1px);
      margin-top: 0;
    }
  }
}
@keyframes circle {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
