<template>
  <div class="open-player">
    <gaudy-title label="课程学习">
      <div class="back-btn" @click="handlerBack">
        <svg-icon icon="icon-fanhui" />
        <span>返回上一页</span>
      </div>
    </gaudy-title>
    <div class="open-player-body">
      <div class="open-player-body-left">
        <div class="open-player-body-title">
          {{ playerInfo.courseContName }}
        </div>
        <div class="open-player-body-content">
          <video-player
            v-if="playerListInfo.length > 0"
            ref="videoPlayer"
            :if-drag-seek="playerInfo.isWanCheng"
            :focus="false"
            :ifTabFocus="true"
            :ifBrush="true"
            :brushTime="1800"
            @play="playerStart"
            @timeupdate="videoPlayerTimeUpDate"
            @ended="playEnded"
            :playerList="playerListInfo"
            :currentUrl="playerIndex"
          />
        </div>
        <div class="open-player-body-bottom">
          简介：{{ playerInfo.introduction }}
        </div>
      </div>
      <div class="open-player-body-right">
        <div class="open-player-right-title">
          所有课程
          <span>（{{ playUrlIndex }}/{{ playerList.length }}）</span>
        </div>
        <el-scrollbar ref="scroll" class="open-player-right-content">
          <div
            class="video-list-item"
            :ref="`currentStudy${index + 1}`"
            :class="{ active: item.currentStudy }"
            v-for="(item, index) in playerList"
            :key="index"
            @click="playClick(item)"
          >
            <div class="list-item-name">
              <img src="@/assets/icon/loading.gif" v-if="item.currentStudy" />
              <div class="title">
                {{ item.courseContName }}
              </div>
            </div>
            <div class="list-item-box" v-if="item.isWanCheng && item.isTongGuo">
              <svg-icon icon="icon-wancheng" />
            </div>
            <div
              class="list-item-status"
              v-if="item.isWanCheng && !item.isTongGuo"
            >
              待考试
            </div>
          </div>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>
<script>
import VideoPlayer from "@/components/videoPlayer.vue";
import SvgIcon from "@/components/svgIcon.vue";
import GaudyTitle from "@/components/gaudyTitle.vue";
import { getGongXuProcessUserCourseList } from "@/api/publicDemandController";
import {
  getStudyProcessEnd,
  getStudyProcessStart,
  getStudyProcessUpdate,
} from "@/api/specializedController";
import { showModel } from "@/utils/tools";
import * as storage from "@/utils/storage";

export default {
  components: { GaudyTitle, SvgIcon, VideoPlayer },
  data() {
    return {
      playerIndex: 0,
      playerList: [],
      playerListInfo: [],
      courseContId: 0,
      playerInfo: {},
      forTheFirstTime: true,
      studyProcessId: undefined,
      editPlayTimer: null,
    };
  },
  computed: {
    playUrlIndex() {
      return this.playerList.findIndex((item) => item.currentStudy == true) + 1;
    },
    yearId() {
      return storage.getStore("yearId");
    },
  },
  created() {
    this.courseContId = this.$route.query.courseContId;
    this.getPublicInfo();
  },
  beforeDestroy() {
    clearInterval(this.editPlayTimer);
    this.editPlayTimer = null;
  },
  methods: {
    // 获取公需课详情
    getPublicInfo() {
      this.playerListInfo = [];
      getGongXuProcessUserCourseList({
        courseContId: this.courseContId,
        trainingPlanId: this.yearId,
      }).then((res) => {
        this.playerList = JSON.parse(JSON.stringify(res.data.courseList));
        this.playerInfo = res.data;
        res.data.videoUrl = JSON.parse(res.data.videoUrl);
        res.data.videoUrl.map((item) => {
          // item.url = "http://zyys.ihehang.com" + item.url;
          item.url = item.url;
        });
        this.playerListInfo = JSON.parse(JSON.stringify(res.data.videoUrl));
        if (res.data.isWanCheng && !res.data.isTongGuo) {
          showModel({
            content: "恭喜您已经完成所有课程学习，请前往考试吧",
            showClose: true,
            showCancelButton: true,
            confirmButtonText: "前往考试",
            closeModal: false,
            confirm: () => {
              console.log("前往考试");
              this.keepAliveNavigation(
                "examination?courseId=" + res.data.studyCourseId
              );
            },
          });
        }
        this.$nextTick(() => {
          this.$refs["scroll"].wrap.scrollTop =
            this.$refs[`currentStudy${this.playUrlIndex}`][0].offsetTop -
            this.$refs["scroll"].wrap.offsetHeight / 2;
        });
      });
    },
    // 开始播放视屏
    playerStart(args) {
      const that = this;
      if (that.forTheFirstTime) {
        this.forTheFirstTime = false;
        getStudyProcessStart({
          ppXmWjbzp5b8: this.playerInfo.courseContId,
          c3R1ZHlDb3Vyc2VJZA: this.playerInfo.studyCourseId,
          trainingId: this.yearId,
        }).then((res) => {
          this.studyProcessId = res.data.studyProcessId;
          // 自动转跳到记录播放时间
          if (res.data.playingTime) {
            args.currentTime(res.data.playingTime);
          }
          // 每间隔2分钟记录一次时间
          if (!this.editPlayTimer) {
            this.editPlayTimer = setInterval(() => {
              that.setStudyProcessEdit();
            }, 120000);
          }
        });
      }
    },
    // 记录播放时间请求
    setStudyProcessEdit() {
      getStudyProcessUpdate({
        c3R1ZHlQcm9jZXNzSWQ: this.studyProcessId,
        cgxhewluz1rpbwu: Math.ceil(this.currentTime),
        trainingId: this.yearId,
      }).then(() => {});
    },
    // 进度条变化
    videoPlayerTimeUpDate(args) {
      this.currentTime = args.currentTime();
    },
    // 视频播放结束请求
    playEnded() {
      getStudyProcessEnd({
        c3R1ZHlQcm9jZXNzSWQ: this.studyProcessId,
        trainingId: this.yearId,
      }).then((res) => {
        if (this.editPlayTimer) {
          clearInterval(this.editPlayTimer);
          this.editPlayTimer = null;
        }
        this.forTheFirstTime = true;
        this.$set(this.playerList[this.playUrlIndex - 1], "isWanCheng", true);
        if (res.data.state === 2) {
          showModel({
            content: "恭喜您已经完成所有课程学习，请前往考试吧",
            showClose: true,
            showCancelButton: true,
            confirmButtonText: "前往考试",
            closeModal: false,
            confirm: () => {
              console.log("前往考试");
              this.keepAliveNavigation(
                "examination?courseId=" + res.data.courseId
              );
            },
          });
        }
      });
    },
    playClick(item) {
      if (this.courseContId == item.courseContId) {
        return false;
      }
      this.courseContId = item.courseContId;
      if (this.editPlayTimer) {
        clearInterval(this.editPlayTimer);
        this.editPlayTimer = null;
      }
      this.forTheFirstTime = true;
      const query = JSON.parse(JSON.stringify(this.$route.query));
      query.courseContId = item.courseContId;
      this.$router.replace({
        path: this.$route.path,
        query: query,
      });
      this.getPublicInfo();
    },
  },
};
</script>
<style scoped lang="scss">
.back-btn {
  border-radius: 52px;
  padding: 6px 16px;
  background: #b50413;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
  span {
    margin-left: 6px;
  }
}
.open-player {
  padding: 16px 0;
  .open-player-body {
    background-color: #ffffff;
    padding: 24px;
    margin-top: 16px;
    box-sizing: border-box;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    height: 800px;
    .open-player-body-left {
      width: calc(100% - 420px);
      margin-right: 20px;
      display: flex;
      flex-direction: column;
      .open-player-body-title {
        font-size: 24px;
        color: #21252e;
        margin-bottom: 5px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .open-player-body-content {
        margin: 10px 0;
        width: 100%;
        flex: 15;
        background-color: #000000;
      }
      .open-player-body-bottom {
        color: #505363;
        font-size: 14px;
        line-height: 1.5;
      }
    }
    .open-player-body-right {
      width: 400px;
      height: 760px;
      box-sizing: border-box;
      background-color: #f1f2f3;
      border-radius: 6px;
      .open-player-right-title {
        font-weight: 500;
        color: #21252e;
        font-size: 16px;
        padding: 10px 15px;
        span {
          font-size: 12px;
          color: #818496;
        }
      }
      .open-player-right-content {
        height: 719px;
        .video-list-item {
          margin: 0 15px 10px;
          padding: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: calc(100% - 50px);
          cursor: pointer;
          .list-item-name {
            display: flex;
            align-items: center;
            width: calc(100% - 45px);
            .title {
              width: calc(100% - 20px);
              color: #505363;
              font-size: 14px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
            img {
              width: 20px;
              height: 18px;
            }
          }
          .list-item-box {
            width: 17px;
            height: 17px;
            font-size: 16px;
            line-height: 17px;
            color: #67c23a;
          }
          .list-item-status {
            font-size: 12px;
            width: 45px;
            white-space: nowrap;
            padding: 2px 5px;
            border-radius: 4px;
            color: #fff;
            display: inline-block;
            background: #e6a23c;
            box-sizing: border-box;
          }
          &.active {
            background: #ffffff;
            border-radius: 4px;
            .list-item-name {
              font-weight: 500;
              .title {
                color: #1677fe;
              }
            }
          }
          &:hover {
            background: #ffffff;
            border-radius: 4px;
            .list-item-name {
              .title {
                color: #1677fe;
              }
            }
          }
        }
      }
    }
  }
}

::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}
::v-deep .confirm-btn {
  background-color: #b50413;
  border-color: #b50413;
}
</style>
